import { Header } from "../../../components/Header";
import { CardContainer, ContainerBackground, IconImage, InfoRowContainer, LeftContainer, RightContainer, SideContainer, SummaryInfos, TextDescription, Title } from "./styles";
import checkYellow from "../../../assets/images/admin/checkYellow.png";
import count from "../../../assets/images/admin/count.png";
import question from "../../../assets/images/admin/question.png";
import xYellow from "../../../assets/images/admin/xYellow.png";
import { CheckBox } from "../../../components/CheckBox";
import { useAdminRsvp } from "./hooks/useAdminRsvp";
import { AdminRsvpCard } from "./components/AdminRsvpCard";
import { WppMessage } from "./components/WppMessage";
import { useWppMessage } from "./hooks/useWppMessage";

export const AdminGuestManager = () => {

    const { rsvps, filterType, confirmedCounter, declinedCounter, pendingCounter, totalCounter, setFilterType } = useAdminRsvp();
    const {sendWppMessage} = useWppMessage();

    return (
        <ContainerBackground>
            <Header />
            <Title>GESTÃO DE CONVIDADOS</Title>
            <SideContainer>
                <LeftContainer>
                    <InfoRowContainer>
                        <IconImage src={count} alt="Total" />
                        <TextDescription>Total: {totalCounter}</TextDescription>
                    </InfoRowContainer>
                    <InfoRowContainer>
                        <IconImage src={checkYellow} alt="Confirmados" />
                        <TextDescription>Confirmados: {confirmedCounter}</TextDescription>
                    </InfoRowContainer>
                    <InfoRowContainer>
                        <IconImage src={xYellow} alt="Recusados" />
                        <TextDescription>Recusados: {declinedCounter}</TextDescription>
                    </InfoRowContainer>
                    <InfoRowContainer>
                        <IconImage src={question} alt="Pendentes" />
                        <TextDescription>Pendentes: {pendingCounter}</TextDescription>
                    </InfoRowContainer>
                </LeftContainer>
                <RightContainer>
                    <WppMessage />
                </RightContainer>
            </SideContainer>
            <SummaryInfos>
                <InfoRowContainer>
                    <TextDescription>Filtrar por: </TextDescription>
                    <CheckBox
                        label="Todos"
                        checked={filterType === "all"}
                        onChange={() => setFilterType("all")}
                    />
                    <CheckBox
                        label="Recusados"
                        checked={filterType === "declined"}
                        onChange={() => setFilterType("declined")}
                    />
                    <CheckBox
                        label="Pendentes"
                        checked={filterType === "pending"}
                        onChange={() => setFilterType("pending")}
                    />
                </InfoRowContainer>
            </SummaryInfos>
            <CardContainer>
                {
                    rsvps.length === 0 ? <Title>Nenhum convite encontrado</Title> :
                    rsvps.map((rsvp) => (
                        <AdminRsvpCard 
                            key={rsvp._id} 
                            rsvpInfos={rsvp} 
                            onSendMessage={() => sendWppMessage(rsvp)}
                            onEditInvite={() => null}
                        />
                    ))
                }
            </CardContainer>
        </ContainerBackground>
    );
}