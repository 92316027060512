import { useState, useEffect, useMemo } from "react";
import lodash from "lodash";
import { GuestsFamily } from "../../../../types/api/invitedFamily";
import { getData, saveData } from "../../../../services/storage";

const CACHE_KEY = "wppMessage";

const DEFAULT_WPP_MESSAGE =
    "Olá, faço parte da equipe do casamento da Mayara e do João Victor e vi que você ainda não fez a confirmação de presença de todos os convidados. Por favor, acesse o link abaixo e confirme sua presença. Agradeço desde já!";

export const useWppMessage = () => {
    const [wppMessage, setWppMessage] = useState<string>(
        getData(CACHE_KEY) || DEFAULT_WPP_MESSAGE
    );

    const debouncedSaveToCookies = useMemo(() =>
        lodash.debounce((message: string) => {
            saveData(CACHE_KEY, message);
        }, 500), []);

    useEffect(() => {
            debouncedSaveToCookies(wppMessage);
    }, [wppMessage, debouncedSaveToCookies]);

    const sendWppMessage =(guestInvite: GuestsFamily) => {
        const savedMessage = getData(CACHE_KEY) || DEFAULT_WPP_MESSAGE;
        console.log("Sending message: ", savedMessage);
        const message = `${savedMessage}\n\n https://joaoemayara.com/rsvp/${guestInvite._id}`;
        const wppLink = `https://wa.me/${guestInvite.telephoneNumber}?text=`;
        const encodedMessage = encodeURIComponent(message);
        console.log("Sending message: ", `${wppLink}${encodedMessage}`);
        window.open(`${wppLink}${encodedMessage}`);
    };

    return {
        wppMessage,
        setWppMessage,
        sendWppMessage,
    };
};