import React from "react";
import { InputContainer } from "./styles";
import MainInput from "../../../../../components/MainInput";
import { useWppMessage } from "../../hooks/useWppMessage";


export const WppMessage = () => {

    const {wppMessage, setWppMessage} = useWppMessage();

    return (
        <InputContainer>
            <MainInput 
                value={wppMessage}
                onChange={(newMessage) => setWppMessage(newMessage)}
                placeholder="Digite a mensagem que será enviada via whatsapp. O link correspondente será anexado automaticamente ao final da mensagem."
                type="text"
                rowsNumber={4}
                title="Mensagem default para o convidado (WhatsApp): "
                titleStyle={{fontFamily: "inter"}}
                inputStyle={{borderRadius: "16px"}}
            />
        </InputContainer>
    );
}