import { useEffect, useMemo, useState } from "react";
import { getAllRsvps } from "../../../../services/api/rsvp";
import { GuestName, GuestsFamily } from "../../../../types/api/invitedFamily";
import toast from "react-hot-toast";

export const useAdminRsvp = () => {
    const [rsvps, setRsvps] = useState<GuestsFamily[]>([]);
    const [filteredRsvps, setFilteredRsvps] = useState<GuestsFamily[]>([]);
    const [filterType, setFilterType] = useState<"all" | GuestName["status"]>("all");
    const rsvpByName = useMemo(() => rsvps.map((rsvp) => rsvp.names), [rsvps]);
    const confirmedCounter = useMemo(() => rsvpByName.flat().filter((name) => name.status === "confirmed").length, [rsvpByName]);
    const declinedCounter = useMemo(() => rsvpByName.flat().filter((name) => name.status === "declined").length, [rsvpByName]);
    const pendingCounter = useMemo(() => rsvpByName.flat().filter((name) => name.status === "pending").length, [rsvpByName]);
    const totalCounter = useMemo(() => rsvpByName.flat().length, [rsvpByName]);

    useEffect(() => {
        getAllRsvps()
            .then((data) => {
                setRsvps(data);
                setFilteredRsvps(data); // Inicializa com todos os RSVPs
            })
            .catch((error) => {
                console.error("Error on get all RSVPs", error);
                toast.error("Erro ao buscar os RSVPs");
            });
    }, []);

    useEffect(() => {
        if (filterType === "all") {
            setFilteredRsvps(rsvps);
        } else {
            setFilteredRsvps(
                rsvps.filter((rsvp) => rsvp.names.some((name) => name.status === filterType))
            );
        }
    }, [filterType, rsvps]);

    return {
        rsvps: filteredRsvps,
        filterType,
        confirmedCounter,
        declinedCounter,
        pendingCounter,
        totalCounter,
        setFilterType,
    };
};
