import styled from "styled-components";

export const ContainerBackground = styled.div`
    background-color: ${(props) => props.theme.palette.primaryColor};
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    box-sizing: border-box;
    color: ${(props) => props.theme.palette.text};
`;

export const LogoImage = styled.img`
    object-fit: cover;
    width: 100%;
    height: 100%;
    border-radius: 12px;
    margin-bottom: 25px;
`;

export const InfoContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    gap: 20px;
    width: 80%;
    max-width: 500px;
    min-width: 200px;
`;

export const ButtonContainer = styled.div`
    max-width: 200px;
    width: 100%;
    margin-top: 25px;
`;
