import React from "react";
import { Header } from "../../../components/Header";
import { CardTitle, ContainerBackground, InfoContainer, Title } from "./styles";
import { TouchableCard } from "../../../components/TouchableCard";
import { useNavigate } from "react-router-dom";
import { GENERAL_ROUTES } from "../../../constants/routes";

export const AdminMainOptions = () => {

    const navigate = useNavigate();

    return (
        <ContainerBackground>
            <Header />
            <Title>Área do Administrador</Title>
            <InfoContainer>
                <TouchableCard onClick={() => { navigate(GENERAL_ROUTES.ADMIN_GUEST_MANAGER.route) }}>
                    <CardTitle>Gestão de convidados</CardTitle>
                </TouchableCard>
            </InfoContainer>
        </ContainerBackground>
    );
}