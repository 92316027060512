import { MainButton } from "../../../../../components/MainButton"
import { OutlineButton } from "../../../../../components/OutlineButton"
import { GuestsFamily } from "../../../../../types/api/invitedFamily"
import { BottomInfosContainer, CardContainer, FamilyNameText, FamilyRowContainer, GuestButtonGroupContainer, GuestHeaderInfoContainer, GuestInfoText, InformativeText, StatusContainer, StatusIcon, TableContainer, TopInfosContainer } from "./styles"
import checkGreen from "../../../../../assets/images/admin/checkGreen.png"
import xBrown from "../../../../../assets/images/admin/xBrown.png"
import questionGreen from "../../../../../assets/images/admin/questionGreen.png"

interface AdminRsvpCardProps {
    rsvpInfos: GuestsFamily,
    onSendMessage: () => void,
    onEditInvite: () => void,
}

export const AdminRsvpCard = ({ rsvpInfos, onSendMessage, onEditInvite }: AdminRsvpCardProps) => {

    const formatPhoneNumber = (phoneNumber: string) => {
        return `+${phoneNumber.substring(0, 2)} (${phoneNumber.substring(2, 4)}) ${phoneNumber.substring(4, 9)}-${phoneNumber.substring(9)}`
    }

    const formatStatus = (status: string) => {
        switch (status) {
            case "confirmed":
                return "Confirmado"
            case "declined":
                return "Recusado"
            case "pending":
            default:
                return "Pendente"
        }
    }

    const getIcon = (status: string) => {
        switch (status) {
            case "confirmed":
                return checkGreen
            case "declined":
                return xBrown
            case "pending":
            default:
                return questionGreen
        }
    }

    return (
        <CardContainer>
            <TopInfosContainer>
                <GuestHeaderInfoContainer>
                    <InformativeText>Nome no convite:</InformativeText>
                    <GuestInfoText>{rsvpInfos.inviteName}</GuestInfoText>
                    <InformativeText>Telefone:</InformativeText>
                    <GuestInfoText>{formatPhoneNumber(rsvpInfos.telephoneNumber)}</GuestInfoText>
                </GuestHeaderInfoContainer>
                <GuestButtonGroupContainer>
                    <MainButton onClick={onSendMessage}>Enviar mensagem</MainButton>
                    <OutlineButton onClick={onEditInvite}>Editar convite</OutlineButton>
                </GuestButtonGroupContainer>
            </TopInfosContainer>
            <BottomInfosContainer>
                <InformativeText>Integrantes da família:</InformativeText>
                <TableContainer>
                    {
                        rsvpInfos.names.map((familyMember, index) => {
                            return (
                                <FamilyRowContainer key={familyMember._id} index={index} status={familyMember.status}>
                                    <FamilyNameText status={familyMember.status} >{familyMember.name}</FamilyNameText>
                                    <StatusContainer>
                                        <StatusIcon src={getIcon(familyMember.status)} alt="Status" />
                                        <FamilyNameText status={familyMember.status}>{formatStatus(familyMember.status)}</FamilyNameText>
                                    </StatusContainer>
                                </FamilyRowContainer>
                            )
                        })
                    }
                </TableContainer>
            </BottomInfosContainer>
        </CardContainer>
    )
}