import { useEffect, useState } from "react";
import { useAuthContext } from "../../../../store/useAuthContext"
import { useNavigate } from "react-router-dom";
import { GENERAL_ROUTES } from "../../../../constants/routes";
import toast from "react-hot-toast";


export const useLogin = () => {
    const {login, sessionToken, userEmail} = useAuthContext();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [emailError, setEmailError] = useState("");
    const [passwordError, setPasswordError] = useState("");
    const navigate = useNavigate();

    useEffect(() => {
        if(sessionToken && userEmail) {
            navigate(GENERAL_ROUTES.ADMIN_MAIN_OPTIONS.route);
        }
    }, [sessionToken, userEmail, navigate]);

    const handleLogin = async () => {
        let allRight = true;
        if(email === "") {
            setEmailError("Digite um e-mail válido");
            allRight = false;
        }else {
            setEmailError("");
        }

        if(password === "") {
            setPasswordError("Digite uma senha válida");
            allRight = false;
        } else {
            setPasswordError("");
        }

        if(!allRight) {
            return;
        }

        toast.promise(login(email, password), {
                loading: "Entrando...",
                success: "Login efetuado com sucesso!",
                error: "Erro ao fazer login. Verifique suas credenciais e tente novamente."
            }
        );
    }

    return {
        email,
        setEmail,
        password,
        setPassword,
        emailError,
        passwordError,
        handleLogin
    }

}