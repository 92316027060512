import styled from "styled-components";
import { DEVICE } from "../../../constants/ScreenSizes";

export const ContainerBackground = styled.div`
    background-color: ${(props) => props.theme.palette.primaryColor};
    width: 100vw;
    min-height: 100vh;
    display: flex;
    align-items: center;
    flex-direction: column;
    box-sizing: border-box;
    color: ${(props) => props.theme.palette.text};
`;

export const Title = styled.h1`
    ${(props) => props.theme.typography.primaryTitle};
    font-size: 24px;
    margin-top: 120px;
    margin-bottom: 10px;
    text-align: center;
    color: ${(props) => props.theme.palette.text};
`;

export const CardTitle = styled.h2`
    ${(props) => props.theme.typography.secondaryTitle};
    font-size: 20px;
    text-align: center;
    color: ${(props) => props.theme.palette.secondaryColor};
`;

export const InfoRowContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
`;

export const IconImage = styled.img`
    width: 28px;
    height: 28px;
    object-fit: cover;
`;

export const TextDescription = styled.p`
    ${(props) => props.theme.typography.secondaryText};
    text-align: center;
    color: ${(props) => props.theme.palette.text};
    font-size: 18px;
    margin: 10px 0;
`;

export const SummaryInfos = styled.div`
    display: flex;
    flex-direction: column;
    width: 80%;
    max-width: 900px;
    align-items: flex-start;
    margin-top: 30px;
    flex: 1;
`;

export const SideContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 80%;
    max-width: 900px;
    margin-top: 30px;

    @media ${DEVICE.TABLET} {
        flex-direction: row;
    }
`;

export const LeftContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    min-width: 250px;
`;

export const RightContainer = styled.div`
    display: flex;
    flex: 3;
    margin-top: 20px;

    @media ${DEVICE.TABLET} {
        margin-top: 0;
    }
`;


export const CardContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    width: 80%;
    max-width: 900px;
    min-height: 60vh;
    margin-bottom: 30px;
`;

