import styled from "styled-components";

export const ContainerBackground = styled.div`
    background-color: ${(props) => props.theme.palette.primaryColor};
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    flex-direction: column;
    box-sizing: border-box;
    color: ${(props) => props.theme.palette.text};
`;

export const InfoContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 60px;
    gap: 20px;
    width: 80%;
    max-width: 500px;
    min-width: 200px;
`;

export const Title = styled.h1`
    ${(props) => props.theme.typography.primaryTitle};
    font-size: 24px;
    margin-top: 100px;
    margin-bottom: 10px;
    text-align: center;
    color: ${(props) => props.theme.palette.text};
`;

export const CardTitle = styled.h2`
    ${(props) => props.theme.typography.secondaryTitle};
    font-size: 20px;
    text-align: center;
    color: ${(props) => props.theme.palette.secondaryColor};
`;

