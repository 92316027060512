import React from "react";
import { CardBackground } from "./styles";

interface TouchableCardProps {
    children: React.ReactNode;
    onClick: () => void;
}


export const TouchableCard = ({children, onClick}: TouchableCardProps) => {
    return (
        <CardBackground onClick={onClick}>
            {children}
        </CardBackground>
    );
}