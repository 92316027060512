import { api } from ".";
import { AdminSession } from "../../types/api/admin";


export const doAdminLogin = (email: string, password: string) => {
    return new Promise<AdminSession>((resolve, reject) => {
        api.post("/adminLogin", {
            email,
            password
        }).then((response) => resolve(response.data)).catch((error) => reject(error));
    });
}