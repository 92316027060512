import styled from "styled-components";

export const CardBackground = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${(props) => props.theme.palette.text};
    border-radius: 10px;
    padding: 10px;
    width: 100%;
    height: 100%;
    cursor: pointer;
    transition: all 0.3s;
    &:hover {
        scale: 1.05;
    }
`;