import React from "react";
import { ButtonContainer, ContainerBackground, InfoContainer, LogoImage } from "./styles";
import { Header } from "../../../components/Header";
import MainInput from "../../../components/MainInput";
import logo from "../../../assets/images/logo.png";
import { MainButton } from "../../../components/MainButton";
import { useLogin } from "./hooks/useLogin";

export const AdminLogin = () => {

    const {email, setEmail, password, setPassword, emailError, passwordError, handleLogin} = useLogin();

    return (
        <ContainerBackground>
            <Header />
            <InfoContainer>
                <LogoImage src={logo} alt="Logo" />
                <MainInput
                    onChange={setEmail}
                    placeholder="Email"
                    value={email}
                    type="email"
                    title="E-mail"
                    errorText={emailError}
                />
                <MainInput
                    onChange={setPassword}
                    placeholder="Senha"
                    value={password}
                    type="password"
                    title="Senha"
                    errorText={passwordError}
                />
                <ButtonContainer>
                    <MainButton secondary onClick={handleLogin}>Entrar</MainButton>
                </ButtonContainer>
            </InfoContainer>
        </ContainerBackground>
    );
}