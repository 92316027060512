import {
    Routes,
    Route,
    BrowserRouter as Router,
} from "react-router-dom";
import { AdminGuestManager, AdminLogin, AdminMainOptions, BlankRsvp, ConfirmationFeedback, GiftList, GiftOrder, GodparentsInvitationGame, Home, HotelRecommendations, Message, OrderFeedback, Rsvp } from "../pages";
import { GENERAL_ROUTES } from "../constants/routes";
import { ScrollToTop } from "../utils/ScrollToTop";
import { PostGameInfos, PreGameInfos } from "../pages/GodparentsInvitation";
import { useAuthContext } from "../store/useAuthContext";

export const MainRoutes = () => {

    const adminSession = useAuthContext((state) => state.sessionToken)

    return (
        <Router>
            <ScrollToTop />
            <Routes>
                <Route path="*" element={<Home />} />
                <Route path={GENERAL_ROUTES.WRITE_MESSAGE.route} element={<Message />} />
                <Route path={GENERAL_ROUTES.GIFT_LIST.route} element={<GiftList />} />
                <Route path={`${GENERAL_ROUTES.GIFT_ORDER.route}/:id`} element={<GiftOrder />} />
                <Route path={GENERAL_ROUTES.ORDER_SUCCESS.route} element={<OrderFeedback />} />
                <Route path={GENERAL_ROUTES.ORDER_FAILURE.route} element={<OrderFeedback />} />
                <Route path={GENERAL_ROUTES.ORDER_PENDING.route} element={<OrderFeedback />} />
                <Route path={GENERAL_ROUTES.HOTEL_RECOMMENDATIONS.route} element={<HotelRecommendations />} />
                <Route path={`${GENERAL_ROUTES.RSVP.route}/:id`} element={<Rsvp />} />
                <Route path={GENERAL_ROUTES.RSVP.route} element={<BlankRsvp />} />
                <Route path={GENERAL_ROUTES.RSVP_FEEDBACK.route} element={<ConfirmationFeedback />} />

                <Route path={`${GENERAL_ROUTES.GODPARENTS_INVITATION_GAME.route}/:id`} element={<GodparentsInvitationGame />} />
                <Route path={`${GENERAL_ROUTES.PRE_GAME_INFOS.route}/:id`} element={<PreGameInfos />} />
                <Route path={`${GENERAL_ROUTES.POST_GAME_INFOS.route}/:id`} element={<PostGameInfos />} />

                <Route path={GENERAL_ROUTES.ADMIN_LOGIN.route} element={<AdminLogin />} />
                {
                    adminSession &&
                        <>
                            <Route path={GENERAL_ROUTES.ADMIN_MAIN_OPTIONS.route} element={<AdminMainOptions />} />
                            <Route path={GENERAL_ROUTES.ADMIN_GUEST_MANAGER.route} element={<AdminGuestManager />} />
                        </>
                }
            </Routes>
        </Router>
    )
}