import { create } from 'zustand'
import { doAdminLogin } from '../services/api/admin'
import { Cookies } from 'react-cookie'

interface AuthState {
    userEmail: string | null,
    sessionToken: string | null,
    login: (user: string, password: string) => Promise<void>
    logout: () => void
    initializeAuth: () => void
}

const cookies = new Cookies()

export const useAuthContext = create<AuthState>((set) => ({
    userEmail: cookies.get('userEmail') || null,
    sessionToken: cookies.get('sessionToken') || null,

    login: async (user, password) => {
        try {
            const { email, token } = await doAdminLogin(user, password)
            
            cookies.set('userEmail', email, { path: '/', secure: true, sameSite: 'strict', expires: new Date(Date.now() + 365 * 24 * 60 * 60 * 1000) })
            cookies.set('sessionToken', token, { path: '/', secure: true, sameSite: 'strict', expires: new Date(Date.now() + 365 * 24 * 60 * 60 * 1000) })

            set({ userEmail: email, sessionToken: token })
        } catch (error) {
            console.error("Error on login", error)
            return Promise.reject(error)
        }
    },

    logout: () => {
        cookies.remove('userEmail', { path: '/' })
        cookies.remove('sessionToken', { path: '/' })

        set({ userEmail: null, sessionToken: null })
    },

    initializeAuth: () => {
        const email = cookies.get('userEmail')
        const token = cookies.get('sessionToken')

        if (email && token) {
            set({ userEmail: email, sessionToken: token })
        }
    }
}))